const testimonials = [
  {
    authorName: 'Anshul Kasan',
    title: 'Co-Founder',
    brandName: 'damnitrahul.com',
    quote:
      'Best hosting providers, they solve my issues really quickly, high in quality and awesome service, recommended.'
  },
  {
    authorName: 'Rahul Sinha',
    title: 'Co-Founder',
    brandName: 'rahul.com',
    quote:
      'As a social media marketing expert, i would suggest everyone  suggest everyone suggest everyone suggest everyone to use Blue Geek Hosting. They have the best customer support ever seen. They are just perfect at all major thing in hosting. Keep it up.'
  },
  {
    authorName: 'Rahul Kumar',
    title: 'Co-Founder',
    brandName: 'rahul.com',
    quote:
      'As a social media marketing expert, e to use Blue Geek Hosting. They have the best customer support ever seen. They are just perfect at all major thing in hosting. Keep it up.'
  }
]
export default testimonials
