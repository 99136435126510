import styled from 'styled-components'
import media from '../utils/breakpoints'
export const TestimonialSectionWrapper = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr;
  padding: 1.5rem 0;
  align-items: center;
  div.testimonial-image {
    img {
      display: block;
      margin: 0 auto;
    }
  }

  div.testimonial-content {
    div.testimonial-heading {
      p {
        font-size: 0.7rem;
        text-transform: uppercase;
        font-weight: bold;
      }
      h2 {
        padding: 0.2rem 0 2rem;
      }
    }
    div.textimonial-body {
      position: relative;
      height: 240px;
      div.controls {
        width: fit-content;
        margin-left: auto;
        display: flex;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 5;
        span {
          cursor: pointer;
          svg {
            display: block;
            margin: 0.5rem;
            color: var(--grey);
            transition: color 350ms ease;
          }
          &:hover {
            svg {
              color: var(--black);
            }
          }
        }
      }
    }
  }

  ${media.md} {
    padding: 3rem 0;
    grid-template-columns: 1fr 1fr;
    div.testimonial-content {
      div.textimonial-body {
        height: 220px;
      }
    }
    div.testimonial-image {
      img {
        margin: 0;
      }
    }
  }
`
export const TestimonialSectionInner = styled.div``

export const TestimonialSingleWrapper = styled.div`
  color: #777;
  position: relative;
  padding-left: 2.5rem;
  div.author {
    padding: 1rem 0;
    p {
      font-family: Jost;
      font-weight: bold;
      color: var(--black);
    }
    span {
      font-size: 0.9rem;
    }
  }
  div.body {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 30px;
      width: 30px;
      background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='30' y='30' width='30' height='30' rx='15' transform='rotate(180 30 30)' fill='%2376FBDD'/%3E%3Cpath d='M16 20L16 13C15.9922 11.7655 16.4415 10.5717 17.2612 9.64859C18.0809 8.72547 19.2132 8.13825 20.44 8C20.5098 7.99081 20.5808 7.99677 20.6481 8.01747C20.7154 8.03818 20.7774 8.07315 20.83 8.12C20.8868 8.17156 20.9315 8.23506 20.9609 8.30595C20.9903 8.37684 21.0037 8.45335 21 8.53V10.53C20.9961 10.6404 20.9557 10.7464 20.8851 10.8315C20.8145 10.9165 20.7178 10.9758 20.61 11C20.1496 11.0914 19.736 11.3419 19.4417 11.7075C19.1474 12.0732 18.991 12.5307 19 13V14H22C22.5304 14 23.0391 14.2107 23.4142 14.5858C23.7893 14.9609 24 15.4696 24 16V20C24 20.5304 23.7893 21.0391 23.4142 21.4142C23.0391 21.7893 22.5304 22 22 22H18C17.4696 22 16.9609 21.7893 16.5858 21.4142C16.2107 21.0391 16 20.5304 16 20ZM8 22L12 22C12.5304 22 13.0391 21.7893 13.4142 21.4142C13.7893 21.0391 14 20.5304 14 20V16C14 15.4696 13.7893 14.9609 13.4142 14.5858C13.0391 14.2107 12.5304 14 12 14H9V13C8.99101 12.5307 9.14736 12.0732 9.4417 11.7075C9.73604 11.3419 10.1496 11.0914 10.61 11C10.7211 10.9749 10.8203 10.9127 10.8912 10.8236C10.9621 10.7345 11.0005 10.6239 11 10.51V8.51C10.9994 8.43837 10.9839 8.36764 10.9547 8.30225C10.9254 8.23686 10.883 8.17821 10.83 8.13C10.7774 8.08315 10.7154 8.04818 10.6481 8.02747C10.5808 8.00677 10.5098 8.00081 10.44 8.01C9.21493 8.14806 8.08406 8.73385 7.26456 9.65487C6.44506 10.5759 5.99471 11.7672 6 13V20C6 20.5304 6.21071 21.0391 6.58579 21.4142C6.96086 21.7893 7.46957 22 8 22Z' fill='white'/%3E%3C/svg%3E%0A");
    }
  }
`
