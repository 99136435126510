import React from 'react'
import { TestimonialSingleWrapper } from './testimonial-styles'
import { motion } from 'framer-motion'

function TestimonialSingle({ data, active }) {
  const { authorName, title, brandName, quote } = data

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      x: 0,
      y: 0,
      transition: {
        duration: 0.3,
        delay: 0.3
      },
      display: 'block'
    },
    exit: {
      opacity: 0,
      rotateX: -10,
      x: 0,
      y: 10,
      transition: {
        duration: 0.3
      },
      transitionEnd: {
        display: 'none'
      }
    }
  }

  // if (!active) return null
  return (
    <motion.div
      className="testimonial-animate"
      initial="exit"
      animate={active ? 'enter' : 'exit'}
      variants={subMenuAnimate}
      style={{ transformOrigin: 'top center' }}
    >
      <TestimonialSingleWrapper>
        <div className="body">
          <p>{quote}</p>
        </div>
        <div className="author">
          <p>{authorName}</p>
          <span>{title}</span>, <span>{brandName}</span>
        </div>
      </TestimonialSingleWrapper>
    </motion.div>
  )
}

export default TestimonialSingle
