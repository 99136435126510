import React, { useState } from 'react'
import { TestimonialSectionWrapper } from './testimonial-styles'
import { Container } from '../common/common-styles'
import testimonialData from '../../content/testimonial/testimonial'
import TestimonialSingle from './testimonial-single'
import lockKeyManSVG from '../../images/illustrations/lock-key-man.svg'
import icon from '../../images/icons/icons.svg'

function TestimonialSection() {
  const [currIndex, setCurrIndex] = useState(0)

  const increaseIndex = () => {
    if (currIndex < testimonialData.length - 1)
      return setCurrIndex(currIndex + 1)
    return setCurrIndex(0)
  }
  const decreaseIndex = () => {
    if (currIndex > 0) return setCurrIndex(currIndex - 1)
    return setCurrIndex(testimonialData.length - 1)
  }

  const allTestimonials = testimonialData.map((item, i) => (
    <TestimonialSingle data={item} key={i} active={currIndex === i} />
  ))

  return (
    <Container>
      <TestimonialSectionWrapper>
        <div className="testimonial-image">
          <img src={lockKeyManSVG} alt="Man with key illustration" />
        </div>
        <div className="testimonial-content">
          <div className="testimonial-heading">
            <p>
              More Over <span className="accent-pink">1000+</span> Active Users
            </p>
            <h2>
              Don’t Believe Us<span className="accent-pink">.</span> <br />
              Check What Our Clients Say About Us
              <span className="accent-pink">.</span>
            </h2>
          </div>
          <div className="textimonial-body">
            {allTestimonials}
            <div className="controls">
              <span
                onClick={decreaseIndex}
                tabIndex="0"
                name="Previous Testimonial"
                onKeyPress={decreaseIndex}
                role="button"
              >
                <svg
                  width="23"
                  height="9"
                  viewBox="0 0 23 9"
                  fill="none"
                  transform="rotate(180)"
                >
                  <use href={`${icon}#long-arrow`} />
                </svg>
              </span>{' '}
              <span
                onClick={increaseIndex}
                tabIndex="0"
                name="Next Testimonial"
                onKeyPress={increaseIndex}
                role="button"
              >
                <svg width="23" height="9" viewBox="0 0 23 9" fill="none">
                  <use href={`${icon}#long-arrow`} />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </TestimonialSectionWrapper>
    </Container>
  )
}

export default TestimonialSection
