import React from 'react'
import aboutSVG from '../../images/features/about.svg'
import aboutSVG2 from '../../images/features/about2.svg'
import { Container, TwoColumnWrapper } from '../common/common-styles'

function AboutPage() {
  return (
    <Container>
      <TwoColumnWrapper>
        <div className="image">
          <img src={aboutSVG} alt="people waving hello" />
        </div>
        <div className="content">
          <h3>By The Bloggers. For The Bloggers.</h3>
          Blue Geek Hosting was launched with the thought of helping small
          business owners, Bloggers, and Marketers. In a growing world of
          Technology online presence is one of the main factors that people need
          to take care about. However, making the online presence can be so
          costly and it’s not easy for everyone to start a website. That is why
          we started Blue Geek Hosting.
        </div>
      </TwoColumnWrapper>
      <TwoColumnWrapper reverse>
        <div className="content">
          <h3>Unparalleled Service and Price</h3>A company that comes with
          Affordable Pricing, Excellent Personal Support and Rock Solid Hosting
          service. We help people to build their business and make them happy
          with our service. We are the India’s cheapest hosting company that
          cares about you
          <h3>Speed and Reliability</h3>
          We have world class Hardware and Software to give you best possible
          Speed and Uptime. We use SSD Storage to provide 3x faster access to
          your files and databases. We use 1 Gbit Network port in our servers to
          give you best network speed.
        </div>
        <div className="image">
          <img src={aboutSVG2} alt="people building website illustration" />
        </div>
      </TwoColumnWrapper>
    </Container>
  )
}

export default AboutPage
