import React from 'react'
import AboutPage from '../components/about/about-page'
import SecondaryHeader from '../components/header/secondary/secondary-header'
import TestimonialSection from '../components/testimonial/testimonial-section'
import SEO from '../components/seo/seo'
function About() {
  const title = 'About Blue Geek Hosting'
  const subtitle =
    'Know the story behind Blue Geek Hosting, and what are goals are.'

  return (
    <>
      <SEO title="About" />
      <SecondaryHeader title={title} subtitle={subtitle} />
      <AboutPage />
      <TestimonialSection />
    </>
  )
}

export default About
